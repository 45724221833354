const cartState = localStorage.getItem("cart");

export default function cart(
  state = cartState
    ? JSON.parse(cartState)
    : { products: [], total: 0, amount: 0.0 },
  action
) {
  let productIndex;
  let productKey;

  switch (action.type) {
    case "ADD_TO_CART":
      productIndex = -1;
      const productAdded = action.payload.product;

      state.products.forEach((product, index) => {
        if (product.key === productAdded.key) {
          productIndex = index;
          return true;
        }
      });

      if (productIndex >= 0) {
        state.products[productIndex].added =
          state.products[productIndex].added +
          parseInt(action.payload.quantity);
      } else {
        productAdded.added = parseInt(action.payload.quantity);
        state.products.push(productAdded);
      }

      state.total = state.total + parseInt(action.payload.quantity);

      state.amount = (
        parseFloat(state.amount) +
        parseInt(action.payload.quantity) * parseFloat(productAdded.price)
      ).toFixed(2);

      localStorage.setItem("cart", JSON.stringify(state));

      return { ...state };

    case "UPDATE_TOTAL":
      productIndex = -1;
      productKey = action.product.productKey;

      state.products.forEach((product, index) => {
        if (product.key === productKey) {
          productIndex = index;
          return true;
        }
      });

      if (productIndex >= 0) {
        state.total =
          state.total -
          state.products[productIndex].added +
          action.product.quantity;

        state.amount = (
          state.amount -
          parseInt(state.products[productIndex].added) *
            parseFloat(state.products[productIndex].price) +
          parseInt(action.product.quantity) *
            parseFloat(state.products[productIndex].price)
        ).toFixed(2);

        state.products[productIndex].added = parseInt(action.product.quantity);
      }

      localStorage.setItem("cart", JSON.stringify(state));

      return { ...state };

    case "REMOVE_FROM_CART":
      productKey = action.productKey;

      const productToRemove = state.products.filter(
        (p) => p.key === productKey
      )[0];

      const newState = {
        ...state,
        products: state.products.filter((p) => p.key !== productKey),
        total: state.total - productToRemove.added,
        amount: (
          state.amount -
          productToRemove.added * parseFloat(productToRemove.price)
        ).toFixed(2),
      };

      localStorage.setItem("cart", JSON.stringify(newState));

      return newState;
    case "RESET_CART":
      return { products: [], total: 0, amount: 0.0 };
    default:
      return state;
  }
}
