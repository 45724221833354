import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@material-ui/core/Grid";
import {
  Button,
  Container,
  Divider,
  Dropdown,
  Form,
  Header,
  Icon,
  Image,
  Input,
  Label,
  List,
  Menu,
  MenuItem,
  Modal,
  Segment,
  TextArea,
} from "semantic-ui-react";
import { isMobile } from "mobile-device-detect";
import Navbar from "./Navbar";

const Cart = () => {
  const cart = useSelector((state) => state.cart);
  const dispatch = useDispatch();

  const updateQuantity = (productKey, quantity) => {
    dispatch({
      type: "UPDATE_TOTAL",
      product: { productKey, quantity: parseInt(quantity) },
    });
  };

  const removeFromCart = (productKey) => {
    dispatch({ type: "REMOVE_FROM_CART", productKey });
  };

  const renderCart = () => {
    const { products } = cart;

    if (products && cart.total > 0) {
      const cartProducts = products.map((product) => {
        let options = [];

        for (var i = 1; i <= product.total; i++) {
          options.push(
            <option value={i} selected={product.added === i}>
              {i}
            </option>
          );
        }

        return (
          <Segment>
            <Grid container>
              <Grid item md={1} xs={3}>
                <Image src={product.image} size="tiny" />
              </Grid>
              <Grid item md={11} xs={9}>
                <Grid style={{ paddingLeft: "1rem" }}>
                  <Grid item>
                    <p
                      style={{
                        fontWeight: "bold",
                        lineHeight: isMobile ? "1rem" : "3rem",
                        width: "95%",
                      }}
                    >
                      {product.name}
                    </p>
                  </Grid>
                  <Grid item>{product.code}</Grid>
                  <Grid item>S/{product.price.toFixed(2)}</Grid>
                </Grid>
              </Grid>
            </Grid>
            <Icon
              id={product.key}
              name="times circle"
              size="large"
              style={{
                cursor: "pointer",
                position: "absolute",
                top: "0.4rem",
                right: 0,
              }}
              onClick={(e) => removeFromCart(e.target.id)}
            />

            <select
              id={product.key}
              style={{
                cursor: "pointer",
                height: "2.5rem",
                textAlignLast: "center",
                width: "3.5rem",
                position: "absolute",
                right: "7.5rem",
                bottom: "1rem",
              }}
              onChange={(e) => updateQuantity(e.target.id, e.target.value)}
            >
              {options}
            </select>

            <p
              style={{
                fontSize: "1.2rem",
                fontWeight: "bold",
                position: "absolute",
                right: "1rem",
                bottom: "1rem",
              }}
            >
              S/{(product.price * product.added).toFixed(2)}
            </p>
          </Segment>
        );
      });

      return cartProducts;
    } else {
      return (
        <p style={{ fontSize: "1.2rem", textAlign: "center" }}>
          Tu carrito de compras esta vacío.
        </p>
      );
    }
  };

  return (
    <div>
      <Navbar />
      <Container
        style={{
          paddingTop: isMobile ? "6.2rem" : "6.5rem",
          paddingBottom: "4.7rem",
        }}
      >
        {renderCart()}
        <Segment
          style={{
            backgroundColor: "#eeeeee",
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100%",
            borderRadius: 0,
            height: "60px",
          }}
        >
          <p
            style={{
              color: "black",
              fontWeight: "bold",
              fontSize: "1.1rem",
              position: "absolute",
              bottom: "0.4rem",
            }}
          >
            TOTAL: S/{cart.amount}
          </p>
          <Button
            color="black"
            labelPosition="right"
            icon="checkmark"
            content={`Confirmar compra`}
            disabled={parseFloat(cart.amount) === 0}
            style={{ position: "absolute", right: "1rem", bottom: "0.85rem" }}
          ></Button>
        </Segment>
      </Container>
    </div>
  );
};

export default Cart;
