import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  Button,
  Container,
  Divider,
  Dropdown,
  Form,
  Grid,
  Header,
  Icon,
  Image,
  Input,
  Label,
  List,
  Menu,
  MenuItem,
  Modal,
  Segment,
  TextArea,
} from "semantic-ui-react";
import Navbar from "./Navbar";
import axios from "axios";
import { isMobile } from "mobile-device-detect";

const { REACT_APP_SERVICE_URL } = process.env;

const Products = () => {
  const params = useParams();
  const searchWords = params.searchWords ? params.searchWords : "GEIM";
  const cart = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const [products, setProducts] = useState([]);

  const searchProduct = async () => {
    const response = await axios.get(
      `${REACT_APP_SERVICE_URL}/products/search/${searchWords}`
    );

    const { data } = response;
    const { products: productsFound } = data;

    if (productsFound) {
      setProducts(productsFound);
    }
  };

  useEffect(() => {
    searchProduct();
  }, [searchWords]);

  const addToCart = (code, id, quantity) => {
    const product = products.filter((pr) => pr.key === id)[0];

    console.log(products);

    dispatch({ type: "ADD_TO_CART", payload: { product, quantity } });
  };

  const renderProducts = () => {
    let content = [];
    let row = [];
    let index = 0;

    products.map((product, i) => {
      let options = [];

      for (var j = 1; j <= product.total; j++) {
        options.push(<option value={j}>{j}</option>);
      }

      row.push(
        <Grid.Column
          key={product.code}
          computer={4}
          mobile={16}
          style={{ paddingTop: "1rem" }}
        >
          <Segment>
            <Image size="medium" src={product.image} centered />
            <p
              style={{
                fontWeight: "bold",
                marginTop: "1rem",
                textAlign: "center",
              }}
            >
              {product.name}
            </p>
            <p>
              <strong>Código:</strong> {product.code}
            </p>
            <p>
              <strong>Rareza:</strong> {product.rarity}
            </p>
            <Grid textAlign="center" verticalAlign="middle">
              <Grid.Row column={2}>
                <Grid.Column computer={5}>
                  <p
                    style={{
                      fontSize: isMobile ? "2rem" : "1.3rem",
                      fontWeight: "bold",
                    }}
                  >
                    S/{product.price.toFixed(2)}
                  </p>
                </Grid.Column>
                <Grid.Column computer={11}>
                  {product.total > 0 && (
                    <div style={{ textAlign: "right" }}>
                      <select
                        name="select"
                        style={{
                          cursor: "pointer",
                          height: "2.5rem",
                          marginRight: ".5rem",
                          textAlignLast: "center",
                          width: "3.5rem",
                        }}
                      >
                        {options}
                      </select>
                      <Button
                        id={product.key}
                        content="Añadir"
                        color="black"
                        onClick={(e) => {
                          addToCart(
                            product.code,
                            e.target.id,
                            e.target.previousElementSibling.value
                          );
                        }}
                      ></Button>
                    </div>
                  )}
                  {product.total === 0 && (
                    <div
                      style={{
                        position: "absolute",
                        bottom: "1rem",
                        right: "1rem",
                      }}
                    >
                      <strong style={{ color: "#d64a37" }}>
                        No disponible
                      </strong>
                    </div>
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Grid.Column>
      );
      index++;

      if ((i + 1) % 4 === 0) {
        content.push(
          <Grid.Row
            key={product.code}
            style={{ width: "100%", paddingBottom: 0, paddingTop: 0 }}
          >
            {row}
          </Grid.Row>
        );
        row = [];
        index = 0;
      }
    });

    if (index + 1 <= 4) {
      content.push(<Grid.Row key="final-row">{row}</Grid.Row>);
    }

    return (
      <Grid
        style={{
          paddingTop: isMobile ? "4.9rem" : "6.5rem",
          justifyContent: "center",
        }}
      >
        {content}
      </Grid>
    );
  };

  return (
    <div>
      <Navbar />
      <Container>{renderProducts()}</Container>
    </div>
  );
};

export default Products;
