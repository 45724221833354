import React, { useState, useEffect } from "react";
import {
  Button,
  Container,
  Divider,
  Dropdown,
  Form,
  Grid,
  Header,
  Icon,
  Image,
  Input,
  Label,
  List,
  Menu,
  MenuItem,
  Modal,
  Segment,
  TextArea,
} from "semantic-ui-react";
import axios from "axios";
import history from "../shared/history";

const { REACT_APP_SERVICE_URL } = process.env;

const AdminProducts = () => {
  const [searchWord, setSearchWord] = useState("GEIM");
  const [products, setProducts] = useState([]);

  const searchProduct = async () => {
    const response = await axios.get(
      `${REACT_APP_SERVICE_URL}/products/search/${searchWord}`
    );

    const { data } = response;
    const { products: productsFound } = data;

    if (productsFound) {
      setProducts(productsFound);
    }
  };

  useEffect(() => {
    searchProduct();
  }, []);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      searchProduct();
    }
  };

  const renderProducts = () => {
    let content = [];
    let row = [];
    let index = 0;

    products.map((product, i) => {
      row.push(
        <Grid.Column key={product.code} computer={4} mobile={16}>
          <Segment>
            <Image size="medium" src={product.image} centered />
            <p
              style={{
                fontWeight: "bold",
                marginTop: "1rem",
                textAlign: "center",
              }}
            >
              {product.name}
            </p>
            <p>
              <strong>Código:</strong> {product.code}
            </p>
            <p>
              <strong>Rareza:</strong> {product.rarity}
            </p>
            <h3>S/{product.price}</h3>
            <Button
              color="black"
              style={{ position: "absolute", bottom: "1rem", right: "1rem" }}
              onClick={() => history.push(`/edit-product/${product.code}`)}
            >
              Editar
            </Button>
          </Segment>
        </Grid.Column>
      );
      index++;
      if ((i + 1) % 4 === 0) {
        content.push(<Grid.Row key={product.code}>{row}</Grid.Row>);
        row = [];
        index = 0;
      }
    });

    if (index + 1 <= 4) {
      content.push(<Grid.Row key="final-row">{row}</Grid.Row>);
    }

    return <Grid style={{ paddingTop: "100px" }}>{content}</Grid>;
  };

  return (
    <div>
      <Segment
        inverted
        style={{ position: "fixed", width: "100%", zIndex: 100 }}
      >
        <Grid style={{ paddingTop: "1rem" }}>
          <Grid.Row columns={3}>
            <Grid.Column computer={3} only="widescreen">
              <h1>Kaytoken Store</h1>
            </Grid.Column>
            <Grid.Column computer={10} mobile={7} textAlign="right">
              <Button
                color="black"
                style={{ border: "1px solid white" }}
                onClick={() => history.push("/create-product")}
              >
                Crear Producto
              </Button>
            </Grid.Column>
            <Grid.Column computer={3} mobile={9} textAlign="right">
              <Input
                action={{ icon: "search", onClick: () => searchProduct() }}
                placeholder="Buscar..."
                fluid
                onChange={(e) => setSearchWord(e.target.value)}
                onKeyDown={(e) => handleKeyDown(e)}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
      <Container>{renderProducts()}</Container>
    </div>
  );
};

export default AdminProducts;
