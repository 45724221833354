import { Router, Switch, Route } from "react-router-dom";
import AdminProducts from "./containers/adminProducts";
import Products from "./containers/products";
import CreateProduct from "./containers/createProduct";
import EditProduct from "./containers/editProduct";
import Cart from "./containers/cart";
import history from "./shared/history";
import "./App.css";

function App() {
  return (
    <Router history={history}>
      <Switch>
        <Route path="/admin-products">
          <AdminProducts />
        </Route>
        <Route path="/products/:searchWords?">
          <Products />
        </Route>
        <Route path="/create-product">
          <CreateProduct />
        </Route>
        <Route path="/edit-product/:code">
          <EditProduct />
        </Route>
        <Route path="/cart">
          <Cart />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
