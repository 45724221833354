import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Grid from "@material-ui/core/Grid";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import Badge from "@material-ui/core/Badge";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { Button, Icon, Image, Input } from "semantic-ui-react";
import { isMobile } from "mobile-device-detect";
import { useSelector } from "react-redux";
import history from "../shared/history";

const useStyles = makeStyles((theme) => ({}));

const KaytokenNavbar = () => {
  const classes = useStyles();
  const cart = useSelector((state) => state.cart);
  const [searchWord, setSearchWord] = useState("GEIM");

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      history.push(`/products/${searchWord}`);
    }
  };

  const path = window.location.pathname.split("/");

  return (
    <div>
      <AppBar
        position="fixed"
        style={{
          backgroundColor: "black",
          height: "72px",
        }}
      >
        <Toolbar>
          <img
            src={`${process.env.PUBLIC_URL}/kaytoken.png`}
            style={{ cursor: "pointer", height: "80%" }}
            onClick={() => history.push(`/products/${searchWord}`)}
          />

          <Grid container spacing={0}>
            <Grid
              container
              item
              md={11}
              xs={8}
              style={{ justifyContent: "flex-end" }}
            >
              <Input
                action={{
                  color: "twitter",
                  icon: "search",
                  onClick: () => history.push(`/products/${searchWord}`),
                }}
                placeholder="Buscar..."
                style={{
                  width: isMobile ? "95%" : "40%",
                  height: "95%",
                  marginTop: "0px",
                }}
                onChange={(e) => setSearchWord(e.target.value)}
                onKeyDown={(e) => handleKeyDown(e)}
              />
            </Grid>
            <Grid
              item
              md={1}
              xs={4}
              style={{
                alignSelf: "center",
                textAlign: "center",
              }}
            >
              {!isMobile && (
                <Badge
                  badgeContent={cart.total}
                  color="secondary"
                  style={{ cursor: "pointer", marginTop: "12px" }}
                >
                  <Icon
                    name="shopping cart"
                    size="large"
                    onClick={() => history.push("/cart")}
                  />
                </Badge>
              )}
              <div style={{ float: "right" }}>
                <Icon name="user circle" size="large" />
                <p>Leonel</p>
              </div>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {isMobile && path[1] === "products" && (
        <div>
          <Button
            style={{
              borderRadius: "10px",
              position: "fixed",
              bottom: 20,
              right: 20,
              zIndex: 100,
            }}
            color="black"
            icon="cart"
            size="huge"
            onClick={() => history.push("/cart")}
          />
          <button
            style={{
              backgroundColor: "red",
              border: "1px solid red",
              borderRadius: "50%",
              bottom: 53,
              color: "white",
              height: "28px",
              position: "fixed",
              right: 13,
              textAlign: "center",
              width: "28px",
              zIndex: 200,
            }}
          >
            {cart.total}
          </button>
          )
        </div>
      )}
    </div>
  );
};

export default KaytokenNavbar;
