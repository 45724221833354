import React, { useState } from "react";
import { Button, Container, Form, Message } from "semantic-ui-react";
import axios from "axios";
import history from "../shared/history";

const { REACT_APP_SERVICE_URL } = process.env;

const CreateProduct = () => {
  const [code, setCode] = useState("");
  const [exchangePrice, setExchangePrice] = useState(3.6);
  const [applyLowerPrice, setApplyLowerPrice] = useState(true);
  const [cardCreated, setCardCreated] = useState({});

  const submitCard = async () => {
    const response = await axios.post(`${REACT_APP_SERVICE_URL}/card/create`, {
      code,
      exchangePrice,
      applyLowerPrice,
    });

    const { data } = response;
    const { card } = data;

    if (card) {
      setCardCreated(card);
    }
  };

  const redirectToEdit = (code) => {
    history.push(`/edit-product/${code}`);
  };

  return (
    <Container>
      <Form style={{ paddingTop: "10px" }}>
        <Form.Input
          fluid
          label="Código"
          placeholder="LOB-EN001"
          id="form-input-code"
          value={code}
          onChange={(e) => setCode(e.target.value)}
        />
        <Form.Input
          fluid
          label="Tipo de Cambio"
          placeholder="3.6"
          value={exchangePrice}
          onChange={(e) => setExchangePrice(e.target.value)}
        />
        <Form.Checkbox
          label="Aplicar precio más bajo."
          checked={applyLowerPrice}
          onChange={(e, data) => setApplyLowerPrice(data.checked)}
        />
        <Button color="black" type="submit" onClick={() => submitCard()}>
          Crear
        </Button>
        {cardCreated.success && (
          <Message>
            <Message.Header>Carta creada con éxito</Message.Header>
            <p>
              <strong>Nombre:</strong> {cardCreated.name}
            </p>
            <p>
              <strong>Código:</strong> {cardCreated.code}
            </p>
            <p>
              <strong>Rareza:</strong> {cardCreated.rarity}
            </p>
            <p>
              <strong>Precio:</strong> S/{cardCreated.price}
            </p>
            <Button
              color="black"
              onClick={() => redirectToEdit(cardCreated.code)}
            >
              Editar
            </Button>
          </Message>
        )}
      </Form>
    </Container>
  );
};

export default CreateProduct;
