import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button, Container, Form } from "semantic-ui-react";
import axios from "axios";
import history from "../shared/history";

const { REACT_APP_SERVICE_URL } = process.env;

const EditProduct = (props) => {
  const params = useParams();
  const { code: cardCode } = params;
  const [name, setName] = useState("");
  const [code, setCode] = useState(cardCode);
  const [image, setImage] = useState(null);
  const [exchangePrice, setExchangePrice] = useState(3.6);
  const [applyLowerPrice, setApplyLowerPrice] = useState(true);
  const [price, setPrice] = useState(0.0);
  const [total, setTotal] = useState(0);

  useEffect(async () => {
    const response = await axios.get(
      `${REACT_APP_SERVICE_URL}/product/search/${code}`
    );

    const { data } = response;
    const { product } = data;

    if (product) {
      console.log(product);
      setName(product.name);
      setImage(product.image);
      setExchangePrice(product.exchangePrice);
      setApplyLowerPrice(product.applyLowerPrice);
      setPrice(product.price);
      setTotal(product.total ? product.total : 0);
    }
  }, []);

  const saveProduct = async () => {
    try {
      const response = await axios.post(
        `https://kaytoken-backend.herokuapp.com/product/update`,
        {
          name,
          code,
          image,
          exchangePrice,
          applyLowerPrice,
          price,
          total,
        }
      );
      console.log(response);
      history.push("/admin-products");
    } catch (e) {
      alert("No se pudo guardar");
    }
  };

  return (
    <Container>
      <Form style={{ paddingTop: "10px" }}>
        <Form.Input
          fluid
          label="Nombre"
          placeholder="Blue-Eyes White Dragon"
          id="form-input-name"
          value={name}
          onChange={(e) => setCode(e.target.value)}
        />
        <Form.Input
          fluid
          label="Código"
          placeholder="LOB-EN001"
          id="form-input-code"
          value={code}
          onChange={(e) => setCode(e.target.value)}
        />
        <Form.Input
          fluid
          label="Imagen"
          placeholder="URL de imagen"
          id="form-input-image"
          value={image}
          onChange={(e) => setImage(e.target.value)}
        />
        <Form.Input
          fluid
          label="Tipo de Cambio"
          placeholder="3.6"
          value={exchangePrice}
          onChange={(e) => setExchangePrice(e.target.value)}
        />
        <Form.Input
          fluid
          label="Precio"
          placeholder="0.00"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
        />
        <Form.Input
          fluid
          label="Total"
          placeholder="10"
          value={total}
          onChange={(e) => setTotal(e.target.value)}
        />
        <Form.Checkbox
          label="Aplicar precio más bajo."
          checked={applyLowerPrice}
          onChange={(e, data) => setApplyLowerPrice(data.checked)}
        />
        <Button color="black" type="submit" onClick={() => saveProduct()}>
          Guardar
        </Button>
      </Form>
    </Container>
  );
};

export default EditProduct;
